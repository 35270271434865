.edu-div-main {
    display: flex;
}

.edu-div-main > * {
    flex: 1;
}

.edu-title {
    font-size: 56px;
    font-weight: 400;
}

.edu-subtitle {
    text-transform: uppercase;
}

.edu-text-div {
    margin-top: 1.5rem;
}

.edu-image-div {
    text-align: center;
}

.edu-image-div > img {

    max-width: 480px;
    width: 100%;
    height: auto;
}

/* Media Query */
@media (max-width: 1380px), (max-width: 768px) {

    .edu-title {
        font-size: 30px;
        text-align: center;
    }

    .edu-subtitle {
        font-size: 16px;
        line-height: normal;
        text-align: center;
    }

    .edu-text-div {
        text-align: center;
        margin-top: 1.5rem;
    }

    .edu-image-div{
        display: none;
    }


}
