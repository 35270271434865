.skills-main-div {
  display: flex;
}
.skills-text-div {
  margin-left: 50px;
}


.skills-main-div > * {
  flex: 1;
  margin-bottom: 30px;
}



.skills-image-div-main > img {
  max-width: 100%;
  height: auto;
}


.skills-image-div{
 padding-left: 50px;
}

.skills-image-div > img {
  max-width: 100%;
  width: 320px;
  height: auto;
  cursor: pointer;
}

.skills-heading {
  font-size: 56px;
  font-weight: 400;
}

.skills-subhead {
  font-size: 45px;
  font-weight: 600;
}

.dev-icons {
  padding-left: 0;
  list-style: none;
  font-size: 3rem;
  margin-bottom: 0px;
  margin-top: 0px;
  text-align: center;
}

.software-skill-inline {
  display: inline-block;
  margin-right: 30px;
  margin-bottom: 30px;
}

.software-skill-inline:hover {
  color: #645beb;
}

.software-skill-inline {
  color: #868e96;
}

.skill-name{
  font-size: 18px;
}

.skill-icon{
  font-size: 60px;
}

/* Media Query */
@media (max-width: 1380px), (max-width: 768px) {
  .skills-subhead {
    text-align: center;
    font-size: 24px;
  }

  .skill-name{
    font-size: 15px;
  }
  
  .skill-icon{
    font-size: 45px;
  }

  .software-skill-inline {
    margin-right: 20px;
    margin-bottom: 20px;
  }
  
  .skills-image-div{
    padding-left: 0px;
   }

}

/* Media Query */
@media (max-width: 1380px) {
  .skills-heading {
    font-size: 40px;
  }
}
@media (max-width: 768px) {
  .skills-heading {
    font-size: 30px;
    text-align: center;
  }
  .greeting-text-p {
    font-size: 16px;
    line-height: normal;
  }
  .skills-main-div {
    flex-direction: column
  }
  .skills-text-div {
    margin-left: 0px;
    margin: 20px;
  }
  .skills-text {
    font-size: 16px;
  }
  .skills-text-subtitle {
    font-size: 16px;
    text-align: center;
  }
  .skills-image-div {
    /* display: none; */
    order: 2;
  }
  .skills-image-div{
    text-align: center;
  }
}
