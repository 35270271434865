.contact-div-main {
    display: flex;
}

.contact-header {
    flex: 80%;
}

.contact-title {
    font-size: 56px;
    font-weight: 400;
}

.contact-detail,
.contact-detail-email {
    margin-top: 20px;
    font-size: 40px;
    font-weight: 400;
    color: #868e96;
    text-decoration: none;
}

.contact-detail:hover,
.contact-detail-email:hover {
    color: black;
    text-shadow: 2px 1px 2px #b5b5b5;
    transition: all 0.3s;
}

.contact-subtitle {
    text-transform: uppercase;
}

.contact-text-div {
    margin-top: 1.5rem;
}



.contact-image-div > img {
    max-width: 100%;
    height: auto;
    margin-left: 1.5rem;
    margin-top: -4rem;
}

.profile-image {
    border-radius: 50%;
    border: 0.5rem solid #6c63ff;
    margin-left: auto;
    width: 100%;
    max-width: 300px;
    height: auto;
  }
  
  .profile-image:hover {
    box-shadow: rgba(0, 0, 0, 1) 0 30px 30px -30px;
    transition: all 0.3s ease-out;
  }
  
  .image-content-profile {
    text-align: right;
    flex: 20%;
    max-width: 100%;
    height: auto;
  }


/* Media Query */
@media (max-width: 1380px), (max-width: 768px) {

    .contact-div-main {
        display: flex;
        flex-direction: column;
      }

    .contact-title {
        font-size: 30px;
        text-align: center;
    }

    .contact-subtitle {
        font-size: 16px;
        line-height: normal;
        text-align: center;
    }

    .contact-text-div {
        text-align: center;
        margin-top: 1.5rem;
    }

    .contact-detail,
    .contact-detail-email {
        font-size: 20px;
    }

    .contact-image-div {
        display: none;
    }
    .image-content-profile {
        margin-left: auto;
        margin-right: auto;
        order: 1;
      }
    
      .desc-prof {
        line-height: 30px;
        font-size: 16px;
      }
    
      .profile-image {
        margin-left: auto;
        margin-right: auto;
        width: 100%;
        max-width: 250px;
        height: auto;
      }
    
      .profile-image:hover {
        box-shadow: rgba(0, 0, 0, 1) 0 50px 50px -50px;
        transition: 0.3s ease-out;
      }

}
